@import '../variables.scss';

.background-trim {
  position: absolute;
  z-index: 3;
  right: 0;
  left: 0;
  height: 1.5rem;
  width: 100%;

  &--top {
    top: 0;
  }

  &--bottom {
    top: 100%;
  }
}

@import '../variables.scss';

.heading {
  font-weight: $font-weight-light;
  font-size: 2rem;
  text-transform: uppercase;
  text-shadow: 0.133em 0.133em $inactive-color;
  margin: 0 0 1rem;

  small {
    font-size: 0.5em;
    font-weight: $font-weight-bold;
    font-family: $noto-sans;
    text-shadow: none;
    white-space: nowrap;
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 3rem;
  }
}

@import '../variables.scss';

.site-header {
  position: relative;

  &__nav {
    max-width: 1000px;
    margin: auto;
    padding: 1rem;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0 0 0 3rem;
    display: flex;
    position: relative;

    @media (max-width: 991px) {
      flex-direction: column;
    }

    &:before {
      content: '';
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 0;
      border-left: 0.8rem solid $inactive-color;
      border-right: 0.25rem solid $inactive-color;
      padding: 0 0.5rem 0 0;
    }
  }

  &__list-item {
    min-width: 8rem;
    flex: 1;

    & + * {
      margin: 0 0 0 1.75rem;

      @media (max-width: 991.98px) {
        margin: 0.5rem 0 0 0;
      }
    }
  }

  &__link {
    text-transform: uppercase;
    font-size: 1.125rem;
  }
}

@import '../variables.scss';

.content-wrapper {
  padding: 1.25rem 1rem;
  background-color: $content-bg;
  position: relative;
  z-index: 0;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: $inactive-color;
    height: 2px;
    right: 0.5rem;
    left: 0.5rem;
  }

  &:before {
    top: 0.25rem;
  }

  &:after {
    bottom: 0.25rem;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

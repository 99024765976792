
@import '../variables.scss';

@keyframes grow-and-shrink {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.025, 1.025);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes left-and-right {
  0% {
    transform: translate(-0.1px, 0);
  }
  50% {
    transform: translate(0.1px, 0);
  }
  100% {
    transform: translate(-0.1px, 0);
  }
}


.background-svg {
  position: absolute;
  z-index: -1;
  opacity: 0.5;
  color: $inactive-color;
  width: 100%;

  &--top {
    top: 0;
    left: 0;
  }

  &--bottom {
    transform: rotate(180deg);
    right: 0;
    bottom: 0;
  }

  &__line {
    transform: translate(-0.1px, 0);

    &:first-of-type {
      animation: left-and-right $grow-and-shrink-duration $grow-and-shrink-duration ease infinite;
    }
    &:nth-of-type(2) {
      animation: left-and-right $grow-and-shrink-duration $grow-and-shrink-duration/3 ease infinite;
    }
    &:nth-of-type(3) {
      animation: left-and-right $grow-and-shrink-duration $grow-and-shrink-duration/2 ease infinite;
    }
  }

  &__circle {
    transform: scale(1);
    &:first-of-type {
      animation: grow-and-shrink $grow-and-shrink-duration $grow-and-shrink-duration/3 ease infinite;
    }
    &:last-of-type {
      animation: grow-and-shrink $grow-and-shrink-duration ease infinite;
    }
  }
}

@import '../variables.scss';

.background-grid {
  color: $inactive-color;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
}

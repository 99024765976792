@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap');

// Typography
$roboto: 'Roboto', sans-serif;
$noto-sans: 'Noto Sans JP', sans-serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

// Colors
$white: #ffffff;
$black: #000000;
$body-bg: #c9c3af;
$body-color: #323426;
$content-bg: #d4d2bf;
// $body-color: rgba(, 0.6);
// $body-color: rgba(#1b1d16, 0.8);
$inactive-color: rgba($body-color, 0.15);
// $link-color: #cb6751;
$link-color: #943f2e;

// Animations
$hover-animation-duration: 0.3s;
$grow-and-shrink-duration: 10s;

// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 922px;
$breakpint-xl: 1200px;
@import './variables.scss';

html {
  font-family: $roboto;
}

html, body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -mox-osx-font-smoothing: grayscale;
}

body {
  visibility: visible;
  background-color: $body-bg;
  color: $body-color;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  // padding: 0 0 2.5rem;
  height: 100vh;
  font-size: 1rem;
  overflow: hidden;
  position: relative;
}

*, *::before, *::after {
  box-sizing: border-box;
}

p {
  margin-top: 0;
}

a {
  color: $link-color;
}

main {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2.5rem;

  &:focus {
    outline: none;
  }
}

ul {
  margin-top: 0;
  list-style-type: square;
}

li {
  margin: 0.5rem;
}

h2, .h2 {
  display: block;
  margin: 0 0 0.5em;
  font-weight: $font-weight-bold;
  font-size: 1.125rem;
}

h3 {
  margin: 0 0 0.5em;
  font-size: 1rem;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.row {
  margin: 0 -1rem;

  @media (min-width: $breakpoint-lg) {
    display: flex;
  }
}

.column {
  padding: 0 1rem;
  margin-bottom: 1rem;

  @media (min-width: $breakpoint-lg) {
    width: 100%;
    flex: 1 1 100%;

    &--lg {
      width: 60%;
      flex: 1 1 60%;
    }

    &--sm {
      width: 40%;
      flex: 1 1 40%;
    }
  }
}

.skip-navigation-link {
  position: absolute;
  height: 0;
  overflow: hidden;
  background-color: $content-bg;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 2.5rem;
  z-index: 3;

  &:focus {
    height: auto;
  }
}

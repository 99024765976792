@import '../variables.scss';

.nav-link {
  display: flex;
  align-items: center;
  color: $body-color;
  text-decoration: none;
  transition: color .3s ease;
  padding: 0.5rem;
  margin: 2px 0;
  background-color: $inactive-color;
  -webkit-tap-highlight-color: $inactive-color;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &--active {
    background-color: $body-color;
    color: $body-bg;

    &[href],
    &:focus,
    &:hover {
      @media (min-width: 992px) {
        &:after {
          transition: width 0s linear, height $hover-animation-duration ease-out;
          top: 4px;
          right: 0;
          bottom: -0.5rem;
          left: 0;
          width: 100%;
          height: 3.6rem;
        }
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    border: 2px solid $body-color;
    border-width: 2px 0;
    z-index: -1 ;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity $hover-animation-duration $hover-animation-duration/2 ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: $body-color;
    z-index: -1;
    top: 4px;
    right: 0;
    bottom: 4px;
    left: 0;
    width: 0;
    transition: width $hover-animation-duration ease-out, height $hover-animation-duration ease-out;
  }

  &:hover,
  &:focus {
    outline: none;
    color: $body-bg;
    text-decoration: none;

    &:before {
      opacity: 1;
    }

    &:after {
      width: 100%;
    }
  }

  &:active {
    opacity: 0.5;
  }

  &--active &__cursor--active,
  &:focus &__cursor {
    opacity: 1;
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
.background-grid{color:rgba(50,52,38,0.15);position:absolute;top:0;right:0;bottom:0;left:0;z-index:-1;height:100vh}

.background-shadow{position:absolute;top:0;right:0;bottom:0;left:0;z-index:-1;height:100vh;width:100%}

@-webkit-keyframes grow-and-shrink{0%{transform:scale(1, 1)}50%{transform:scale(1.025, 1.025)}100%{transform:scale(1, 1)}}@keyframes grow-and-shrink{0%{transform:scale(1, 1)}50%{transform:scale(1.025, 1.025)}100%{transform:scale(1, 1)}}@-webkit-keyframes left-and-right{0%{transform:translate(-0.1px, 0)}50%{transform:translate(0.1px, 0)}100%{transform:translate(-0.1px, 0)}}@keyframes left-and-right{0%{transform:translate(-0.1px, 0)}50%{transform:translate(0.1px, 0)}100%{transform:translate(-0.1px, 0)}}.background-svg{position:absolute;z-index:-1;opacity:0.5;color:rgba(50,52,38,0.15);width:100%}.background-svg--top{top:0;left:0}.background-svg--bottom{transform:rotate(180deg);right:0;bottom:0}.background-svg__line{transform:translate(-0.1px, 0)}.background-svg__line:first-of-type{-webkit-animation:left-and-right 10s 10s ease infinite;animation:left-and-right 10s 10s ease infinite}.background-svg__line:nth-of-type(2){-webkit-animation:left-and-right 10s 3.33333s ease infinite;animation:left-and-right 10s 3.33333s ease infinite}.background-svg__line:nth-of-type(3){-webkit-animation:left-and-right 10s 5s ease infinite;animation:left-and-right 10s 5s ease infinite}.background-svg__circle{transform:scale(1)}.background-svg__circle:first-of-type{-webkit-animation:grow-and-shrink 10s 3.33333s ease infinite;animation:grow-and-shrink 10s 3.33333s ease infinite}.background-svg__circle:last-of-type{-webkit-animation:grow-and-shrink 10s ease infinite;animation:grow-and-shrink 10s ease infinite}

.nav-item{border-width:2px 0;position:relative;z-index:0;margin:0;padding:2px 0}

.cursor-svg{position:absolute;opacity:0;height:2rem;width:2rem;color:#323426;top:0;bottom:0;margin:auto 0;transform:translate(-3rem, 0)}

.nav-bullet-svg{margin-right:0.5rem;height:1rem;width:1rem}

.nav-link{display:flex;align-items:center;color:#323426;text-decoration:none;transition:color .3s ease;padding:0.5rem;margin:2px 0;background-color:rgba(50,52,38,0.15);-webkit-tap-highlight-color:rgba(50,52,38,0.15);-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.nav-link--active{background-color:#323426;color:#c9c3af}@media (min-width: 992px){.nav-link--active[href]:after,.nav-link--active:focus:after,.nav-link--active:hover:after{transition:width 0s linear,height .3s ease-out;top:4px;right:0;bottom:-0.5rem;left:0;width:100%;height:3.6rem}}.nav-link:before{content:'';position:absolute;border:2px solid #323426;border-width:2px 0;z-index:-1;top:0;right:0;bottom:0;left:0;opacity:0;transition:opacity .3s .15s ease-out}.nav-link:after{content:'';position:absolute;background-color:#323426;z-index:-1;top:4px;right:0;bottom:4px;left:0;width:0;transition:width .3s ease-out,height .3s ease-out}.nav-link:hover,.nav-link:focus{outline:none;color:#c9c3af;text-decoration:none}.nav-link:hover:before,.nav-link:focus:before{opacity:1}.nav-link:hover:after,.nav-link:focus:after{width:100%}.nav-link:active{opacity:0.5}.nav-link--active .nav-link__cursor--active,.nav-link:focus .nav-link__cursor{opacity:1}

.background-trim{position:absolute;z-index:3;right:0;left:0;height:1.5rem;width:100%}.background-trim--top{top:0}.background-trim--bottom{top:100%}

.site-header{position:relative}.site-header__nav{max-width:1000px;margin:auto;padding:1rem}.site-header__list{list-style:none;margin:0;padding:0 0 0 3rem;display:flex;position:relative}@media (max-width: 991px){.site-header__list{flex-direction:column}}.site-header__list:before{content:'';position:absolute;top:4px;bottom:4px;left:0;border-left:0.8rem solid rgba(50,52,38,0.15);border-right:0.25rem solid rgba(50,52,38,0.15);padding:0 0.5rem 0 0}.site-header__list-item{min-width:8rem;flex:1 1}.site-header__list-item+*{margin:0 0 0 1.75rem}@media (max-width: 991.98px){.site-header__list-item+*{margin:0.5rem 0 0 0}}.site-header__link{text-transform:uppercase;font-size:1.125rem}

.site-footer{position:fixed;right:0;bottom:0;left:0;padding:1.25rem 1rem}

.heading{font-weight:300;font-size:2rem;text-transform:uppercase;text-shadow:0.133em 0.133em rgba(50,52,38,0.15);margin:0 0 1rem}.heading small{font-size:0.5em;font-weight:700;font-family:"Noto Sans JP",sans-serif;text-shadow:none;white-space:nowrap}@media (min-width: 922px){.heading{font-size:3rem}}

.content-wrapper{padding:1.25rem 1rem;background-color:#d4d2bf;position:relative;z-index:0;overflow:hidden}.content-wrapper:before,.content-wrapper:after{content:'';position:absolute;background-color:rgba(50,52,38,0.15);height:2px;right:0.5rem;left:0.5rem}.content-wrapper:before{top:0.25rem}.content-wrapper:after{bottom:0.25rem}.content-wrapper *:last-child{margin-bottom:0}

.pet-demo{display:inline-block;max-width:100%}

html{font-family:"Roboto",sans-serif}html,body{margin:0;-webkit-font-smoothing:antialiased;-mox-osx-font-smoothing:grayscale}body{visibility:visible;background-color:#c9c3af;color:#323426;z-index:0;font-family:'Roboto', sans-serif;height:100vh;font-size:1rem;overflow:hidden;position:relative}*,*::before,*::after{box-sizing:border-box}p{margin-top:0}a{color:#943f2e}main{height:100%;max-height:100%;overflow:auto;padding-top:2rem;padding-bottom:2rem;margin-bottom:2.5rem}main:focus{outline:none}ul{margin-top:0;list-style-type:square}li{margin:0.5rem}h2,.h2{display:block;margin:0 0 0.5em;font-weight:700;font-size:1.125rem}h3{margin:0 0 0.5em;font-size:1rem}.container{max-width:1000px;margin:0 auto;padding-right:1rem;padding-left:1rem}.row{margin:0 -1rem}@media (min-width: 922px){.row{display:flex}}.column{padding:0 1rem;margin-bottom:1rem}@media (min-width: 922px){.column{width:100%;flex:1 1 100%}.column--lg{width:60%;flex:1 1 60%}.column--sm{width:40%;flex:1 1 40%}}.skip-navigation-link{position:absolute;height:0;overflow:hidden;background-color:#d4d2bf;top:0;right:0;left:0;padding:0 2.5rem;z-index:3}.skip-navigation-link:focus{height:auto}

